import * as React from "react";
import Container from "../components/container";
import Layout from "../components/layout";
//import { StaticImage } from "gatsby-plugin-image";
import {
  mainContent,
  description,
  row,
  //col6,
  col12,
  //marginTopSubstitute,
  introSection,
  buttonGroup,
} from "./subpage.module.scss";
//import { Link } from "gatsby";

const DMEA24Page = () => {
  return (
    <Layout pageTitle="DMEA24" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
        <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>
                 <p className={description}>Event - Februar 2024</p> 
                <h1>Besuchen Sie uns an der DMEA 2024 in Berlin</h1>
               
                <p className="text-medium">
                Vom 09. bis 11. April 2024 finden Sie uns direkt neben dem Schweizer Stand in Halle 6.2, Stand C-106.</p>
                </div>

                <div className={col12}>
                <h3>Diesjährige Themen bei uns am Stand</h3>
                <p>Entdecken Sie dabei unsere neuesten Entwicklungen in den Bereichen Medizinische Interoperabilitätsplattform und die darauf basierende TIE Portalewelt, mit den Komponenten Patientenportal, Zuweiserportal, Klinikportal und Verlegungsportal, für eine umfassende und effiziente Patientenversorgung.</p>
                <p>Diese Neuerungen und weitere Module der health-engine® stellen wir Ihnen auch gerne anhand von Live-Demos vor.</p>
                <div style={{ marginBottom: 40 }} className={buttonGroup}><a href="https://tie.ch/portale" className="button-outline blue" target="_blank" rel="noreferrer"> Mehr Info zur TIE Portalwelt </a> </div>
                  </div>

                  <div className={col12}>
                <h3>Haben Sie Interesse an einem persönlichem Gespräch mit uns?</h3>
                <p>Treffen Sie unsere Experten am Schweizer Stand an der DMEA in Halle 6.2, Stand C-106 für ein persönliches Gespräch. Buchen Sie am besten heute noch Ihren Termin. Wir freuen uns auf den Austausch mit Ihnen.</p>
                <div style={{ marginBottom: 40 }} className={buttonGroup}><a href="https://outlook.office365.com/owa/calendar/DMEA2024@tie.ch/bookings/" className="button-outline blue" target="_blank" rel="noreferrer"> Termin buchen </a> </div>
                  </div>

                  <div className={col12}>
               
                <p>Bleiben Sie über neueste Informationen und Veranstaltungen von the i-engineers stets informiert und melden Sie sich für unseren Newsletter an.</p>
                <div className={buttonGroup}><a 
                    href="https://www.tie.ch/landingpages/newsletter-anmeldung"
                    className="button-outline blue" 
                    target="_blank" 
                    rel="noreferrer"> Zur Newsletter Anmeldung </a> </div>
                  </div>

             
            </div>

            
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default DMEA24Page;
